import axios from "axios";

export const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_URL_CORE_SERVER;

axiosClient.defaults.headers = {
    "x-api-key": process.env.REACT_APP_CORE_API_KEY,
    "content-type": "application/json",
};

//All request will wait 8 seconds before timeout
axiosClient.defaults.timeout = 8000;

export function getRequest(URL) {
    return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload) {
    return axiosClient
        .post(`/${URL}`, payload)
        .then((response) => response)
        .catch((error) => {
            if (error?.response) return error.response;
            return;
        });
}

export function patchRequest(URL, payload) {
    return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
    return axiosClient.delete(`/${URL}`).then((response) => response);
}


// axios.interceptors.request.use((request) => {
//     return request;
// });

// axios.interceptors.response.use(
//     function (response) {
//         //Dispatch any action on success
//         return response;
//     },
//     function (error) {
//         if (error.response.status === 401) {
//             //Add Logic to
//             //1. Redirect to login page or
//             //2. Request refresh token
//         }
//         return Promise.reject(error);
//     }
// );

import React from "react";

import "../../scss/components/LandingBrandLogos.scss";

import { Layout } from "@vwfs-bronson/bronson-react";

export const LandingBrandLogos = () => {
    return (
        <Layout center className={"row landing-brand-section"}>
            <Layout.Item default="1/4" s="1/2">
                <a href={"https://www.volkswagen.es/"} target="_blank" rel="noopener noreferrer">
                    <img alt="volkswagen" src="/images/logos/volkswagen.png" />
                </a>
            </Layout.Item>
            <Layout.Item default="1/4" s="1/2">
                <a href={"https://www.seat.es/"} target="_blank" rel="noopener noreferrer">
                    <img alt="seat" src="/images/logos/seat.png" />
                </a>
            </Layout.Item>
            <Layout.Item default="1/4" s="1/2">
                <a href={"http://www.skoda.es/"} target="_blank" rel="noopener noreferrer">
                    <img alt="skoda" src="/images/logos/skoda.png" />
                </a>
            </Layout.Item>
            <Layout.Item default="1/4" s="1/2">
                <a href={"https://www.volkswagen-comerciales.es/es.html"} target="_blank" rel="noopener noreferrer">
                    <img alt="volkswagen" src="/images/logos/volkswagen-lcv.png" />
                </a>
            </Layout.Item>
        </Layout>
    );
};
